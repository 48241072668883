<!-- 人才管理 -->
<template>
	<div>
		<!-- 通用标题组件 -->

		<el-card class="box-card">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
				<el-breadcrumb-item>人才列表</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="hr-20"></div>

			<div style="padding: 0px;margin: 0px;overflow: hidden;border-bottom: 1px #f9f7f7 solid;">
				<el-form ref="" label-width="0" size="small" style="width: 100%;">
					<v-row v-if="isAllSearch">
						<v-col cols="2">
							<el-form-item>
								<el-input v-model.trim="searchIdentityCode" placeholder="证件编号" clearable />
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model="searchAcademic" placeholder="学历" clearable style="width: 100%">
									<el-option v-for="item in education" :key="item.label" :label="item.label" :value="item.label"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model="searchCitizenship" filterable placeholder="国籍" style="width: 100%" clearable>
									<el-option v-for="item in choiceNationality" :key="item.name" :label="item.name" :value="item.name"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model="rankLevel" filterable placeholder="省内外" style="width: 100%" clearable>
									<el-option v-for="item in rankLeveloptions" :key="item.value" :label="item.value" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model="skillCategory" filterable placeholder="专业领域" style="width: 100%" clearable>
									<el-option v-for="item in professionalFieldoptions" :key="item.value" :label="item.value" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model="searchRankCategory" placeholder="职称" style="width: 100%" clearable>
									<el-option v-for="item in optionsCertificate" :key="item.label" :label="item.label" :value="item.label"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="3">
							<el-form-item>
								<el-date-picker clearable size="small" v-model="searchStartIntroductionTime" type="daterange" range-separator="至" start-placeholder="开始引进时间" end-placeholder="结束引进时间" value-format="yyyy-MM-dd"> </el-date-picker>
							</el-form-item>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="1">
							<el-form-item>
								<el-input v-model.trim="searchName" placeholder="姓名" clearable />
							</el-form-item>
						</v-col>
						<v-col cols="2">
							<el-form-item>
								<el-input v-model.trim="searchPhone" placeholder="手机号" clearable />
							</el-form-item>
						</v-col>
						<v-col cols="2">
							<el-form-item>
								<el-select v-model="searchQyname" filterable placeholder="企业名称" style="width: 100%">
									<el-option v-for="item in Qiyes" :key="item.enterpriseId" :label="item.label" :value="item.enterpriseId"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model.trim="searchSex" placeholder="性别" clearable>
									<el-option v-for="item in Sex" :key="item.value" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="1">
							<el-form-item>
								<el-select v-model="currentStatus" multiple placeholder="状态" @change="setStatus">
									<el-option v-for="item in Status" :key="item.value" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</v-col>
						<v-col cols="3">
							<el-form-item class="fr">
								<el-button type="primary" plain size="mini" @click="getDate(1)"><i class="fa fa-search fa-fw"></i>&nbsp; </el-button>
								<el-button type="success" plain size="mini" @click="requestRefresh" icon="el-icon-refresh"> </el-button>
								<el-button class="mr-4 text-color" size="mini" icon="el-icon-arrow-up" v-if="isAllSearch" @click="getAllSearch(1)">
									收起
								</el-button>
								<el-button class=" mr-4 text-color" size="mini" icon="el-icon-arrow-down" v-else @click="getAllSearch(0)">
									高级搜索
								</el-button>
								<el-button class="" type="warning" plain size="mini" @click="centerDialogVisible = true">
									导出Excel
								</el-button>
							</el-form-item>
						</v-col>
					</v-row>
				</el-form>
			</div>

			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" @row-dblclick="requestSee">
				<el-table-column type="selection" width="55"></el-table-column>

				<el-table-column label="姓名" min-width="120px" align="left">
					<template slot-scope="scope">
						{{ scope.row.name }}
					</template>
				</el-table-column>

				<el-table-column label="性别" align="center" min-width="80px">
					<template slot-scope="scope">
						{{ scope.row.sex === 0 ? "男" : "女" }}
					</template>
				</el-table-column>

				<el-table-column label="民族" align="center" min-width="120px">
					<template slot-scope="scope">{{ scope.row.nation }}</template>
				</el-table-column>
				<el-table-column label="籍贯" align="center" min-width="120px">
					<template slot-scope="scope">{{ scope.row.birthplace }}</template>
				</el-table-column>
				<el-table-column label="供职单位" align="center" min-width="320px">
					<template slot-scope="scope">{{ scope.row.enterpriseName }}</template>
				</el-table-column>

				<el-table-column label="提交认证时间" align="center" min-width="160px">
					<template slot-scope="scope">{{ scope.row.submitTime | formatDate }}</template>
				</el-table-column>

				<el-table-column label="手机号" align="center" min-width="160px">
					<template slot-scope="scope">{{ scope.row.account.contact }}</template>
				</el-table-column>
				<el-table-column label="状态" align="center" min-width="90px">
					<template slot-scope="scope">
						<el-tag effect="dark" :type="isStatustype(scope.row.status)">
							{{ isStatus(scope.row.status) }}
						</el-tag>
						<!-- <el-row v-if="scope.row.status === 11">
              <span>未审核</span>
            </el-row>
            <el-row v-else>
              <span v-if="scope.row.status === 21">已通过</span>
              <span v-else>已驳回</span>
            </el-row> -->
					</template>
				</el-table-column>
				<el-table-column label="操作" width="200px" align="center" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" plain @click="requestSee(scope.row)" icon="el-icon-view">
							查看
						</el-button>
						<el-dropdown>
							<span class="el-dropdown-link dropdown">
								<i class="el-icon-share"></i>
							</span>
							<el-dropdown-menu slot="dropdown" v-if="scope.row.account">
								<el-dropdown-item v-if="scope.row.account.status === 1" class="lid">
									<el-button icon="el-icon-open" @click="SetStatus(0, scope.row.account.id, scope.row)">
										锁定
									</el-button>
								</el-dropdown-item>
								<el-dropdown-item v-else class="lid">
									<el-button plain @click="SetStatus(1, scope.row.account.id)">解锁</el-button>
								</el-dropdown-item>
								<el-dropdown-item @click="resetPass(scope.row)" class="lid">
									<el-button icon="el-icon-orange" @click="resetPass(scope.row)">重置密码</el-button>
								</el-dropdown-item>
								<el-dropdown-item class="lid">
									<el-button icon="el-icon-phone-outline" @click="requestResetPhone(scope.row)">
										重置手机号
									</el-button>
								</el-dropdown-item>
								<el-dropdown-item class="lid">
									<el-button icon="el-icon-edit" @click="handleEdit(scope.row)">编辑角色</el-button>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination id="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize" v-if="!loadFlag" @current-change="requestPages" :current-page="page" />
			<span style="float: left;margin: -35px 0 0 0;">共计 {{ total }} 条数据</span>
		</el-card>

		<!--          导出弹窗-->
		<el-dialog title="导出Excel" :visible.sync="centerDialogVisible" min-width="560px" width="40%" center>
			<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选 </el-checkbox>
			<div style="margin: 15px 0;"></div>
			<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" class="checkboxlist">
				<el-checkbox v-for="city in cities" :label="city" :key="city" class="checkboxitem">
					{{ city }}
				</el-checkbox>
			</el-checkbox-group>

			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" plain @click="requestExport">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog :title="title" :visible.sync="usernum" width="35%">
			<div class="hr-10"></div>
			<el-transfer v-model="rolevalue" :titles="titles" :props="{ key: 'value', label: 'desc' }" :data="roledata" style="text-align: left"> </el-transfer>
			<div class="hr-10"></div>
			<el-button @click="usernum = false">取 消</el-button>
			<el-button type="primary" plain @click="submitForm()">提交</el-button>
		</el-dialog>

		<el-dialog title="修改手机号码" :visible.sync="phoneDialog" width="520px" center :showClose="showClo">
			<v-form ref="phoneForm" v-model="valid" lazy-validation>
				<v-text-field required v-model="phoneForm.newCellphone" :rules="cellRules" dense outlined placeholder="请输入手机号" />
			</v-form>

			<el-button @click="reset">取 消</el-button>
			<el-button type="primary" plain @click="requestPhone">提交</el-button>
		</el-dialog>
	</div>
</template>

<script>
import configDate from "@/utils/config";

const cityOptions = [
	"姓名",
	"性别",
	"生日",
	"民族",
	"证件类型",
	"身份证号码",
	"国籍",
	"籍贯",
	"手机号",
	"邮箱",
	"在中心城市是否有房",
	"是否买房",
	"社保缴纳时间",
	"是否缴纳社保",
	"人才类型",
	"供职单位",
	"人才备注",
	"毕业时间",
	"是否是留学",
	"学历",
	"专业",
	"毕业证书编号",
	"学制",
	//   "第一学历毕业时间",
	//   "第一学历是否是留学",
	//   "第一学历学历",
	//   "第一学历专业",
	//   "第一学历毕业证书编号",
	//   "第一学历学制",
	//   "第二学历是否是留学",
	//   "第二学历学历",
	//   "第二学历专业",
	//   "第二学历毕业时间",
	//   "第二学历毕业证书编号",
	//   "第二学历学制",
	"职位",
	"入职时间",
	"职称类别",
	"户籍住址",
];
export default {
	components: {},
	data() {
		return {
			loadFlag: false,
			phoneDialog: false,
			showClo: false,
			valid: true,
			isAllSearch: false,
			newCellphone: "",
			page: 1,
			pages: 0,
			total: 0,
			pageSize: 10,
			education: configDate.education, // 选择学历
			choiceNationality: configDate.country, // 选择国籍
			optionsCertificate: configDate.optionsCertificate, // 职称类别
			searchName: "", // 查询姓名
			searchAcademic: "", // 查询学历
			searchStartIntroductionTime: [], // 查询引进时间
			searchCitizenship: "", // 查询国籍
			searchRankCategory: "", // 查询职称
			searchIdentityCode: "", // 查询证件号
			// 选择是否省内外
			rankLeveloptions: configDate.introduce,

			rankLevel: "", // 是否省内外
			professionalFieldoptions: configDate.professionalField, // 选择专业领域
			skillCategory: "", // 从事专业领域
			searchPhone: "", // 查询电话号码
			searchQyname: "", //查询 企业名称
			searchSex: "", //查询 性别
			Status: [
				{
					value: 2,
					label: "未审核",
					type: "info",
				},
				{
					value: 10,
					label: "单位驳回",
					type: "warning",
				},
				{
					value: 11,
					label: "单位通过",
					type: "success",
				},
				{
					value: 21,
					label: "管理员通过",
					type: "success",
				},
				{
					value: 20,
					label: "管理员驳回",
					type: "danger",
				},
			],
			Sex: [
				{
					value: 0,
					label: "男",
				},
				{
					value: 1,
					label: "女",
				},
			],
			Qiyes: [],
			currentStatus: [],
			loading: false,
			tableData: [],
			multipleSelection: [],
			centerDialogVisible: false,
			checkAll: false,
			checkedCities: ["姓名", "性别"],
			cities: cityOptions,
			isIndeterminate: true,
			usernum: false,
			titles: ["可选角色", "已选角色"],
			title: "",
			roledata: [],
			rolevalue: [],
			form: {
				id: "",
				roleIds: [],
				status: 0,
			},
			searchCondition: {}, //搜索条件
			phoneForm: {
				newCellphone: "",
				id: "",
			},
			cellRules: [(v) => !!v || "请输入手机号", (v) => /^1[345789]\d{9}$/.test(v) || "请输入正确的手机号"],
		};
	},

	created() {
		this.getEnterpriseInformation();
		if (this.$route.query.page) {
			this.getDate(Number(this.$route.query.page)); //初始化
		} else {
			this.getDate(1); //初始化
		}
	},
	methods: {
		request() {
			let newPage = this.$router.resolve({
				name: "EntryStoma",
			});
			window.open(newPage.href, "_blank");
		},

		getDate(v) {
			let startIntroductionTime = "";
			let endIntroductionTime = "";
			if (this.searchStartIntroductionTime !== null) {
				startIntroductionTime = this.searchStartIntroductionTime[0];
				endIntroductionTime = this.searchStartIntroductionTime[1];
			}
			this.page = v;
			let self = this;
			self.loadFlag = true;
			let arr = [];
			if (self.currentStatus.length === 0) {
				arr = [11, 21];
			} else {
				arr = self.currentStatus;
			}
			this.searchCondition = {
				name: self.searchName,
				academic: self.searchAcademic,
				startIntroductionTime: startIntroductionTime,
				endIntroductionTime: endIntroductionTime,
				citizenship: self.searchCitizenship,
				rankCategory: self.searchRankCategory,
				identityCode: self.searchIdentityCode,
				phone: self.searchPhone,
				statuses: arr,
				rankLevel: self.rankLevel,
				skillCategory: self.skillCategory,
				current: v,
				enterpriseId: self.searchQyname,
				sex: self.searchSex,
				size: this.pageSize,
			};

			this.$axios
				.post("/talent/condition", self.searchCondition)
				.then(function(response) {
					self.loadFlag = false;
					if (response.data.data.total) {
						self.tableData = response.data.data.records;
						self.total = response.data.data.total;
					} else {
						self.tableData = [];
						self.total = 0;
					}
				})
				.catch(function(error) {
					self.loadFlag = false;
					console.error(error);
				});

			//获取 全部角色
			this.$axios
				.get("/role/page", {
					params: {
						current: 1,
						size: 100,
					},
				})
				.then(function(res) {
					self.roledata = [];
					self.rolevalue = [];
					for (let i = 0; i < res.data.data.records.length; i++) {
						self.roledata.push({
							value: res.data.data.records[i].id,
							desc: res.data.data.records[i].name,
						});
					}
				});
		},

		// 是否打开全部搜索条件
		getAllSearch(num) {
			this.isAllSearch = num === 0;
		},
		//状态转换
		isStatus(Status) {
			let label = "";
			this.Status.forEach((items) => {
				if (items.value == Status) {
					label = items.label;
					console.log(items.label);
				}
			});
			return label;
		},
		isStatustype(Status) {
			let type = "";
			this.Status.forEach((items) => {
				if (items.value == Status) {
					type = items.type;
					console.log(items.type);
				}
			});
			return type;
		},
		//  查询
		requestQuery() {
			let self = this;
			if (self.searchName === "男") {
				self.searchName = 0;
			}
			if (self.searchName === "" && self.searchPhone === "" && self.currentStatus.length === 0) {
				self.getDate();
				return false;
			}
			this.$axios
				.post("/talent/condition", {
					name: self.searchName,
					phone: self.searchPhone,
					statuses: self.currentStatus,
				})
				.then((response) => {
					self.tableData = response.data.data.records;
					self.total = response.data.data.total;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		//获取公司信息
		getEnterpriseInformation() {
			let self = this;
			this.$axios
				.post("/enterprise/condition", {
					current: 1,
					size: 2000,
					statuses: [21],
				})
				.then(function(res) {
					if (res.data.status === 200) {
						let cars = res.data.data.records;
						cars.forEach((item) => {
							self.Qiyes.push({
								enterpriseId: item.id,
								label: item.name,
							});
						});
					}
				})
				.catch(function(error) {
					console.error(error);
				});
		},
		requestRefresh() {
			this.searchPhone = "";
			this.searchName = "";
			this.searchAcademic = "";
			this.searchStartIntroductionTime = [];
			this.searchCitizenship = "";
			this.searchRankCategory = "";
			this.searchIdentityCode = "";
			this.searchSex = "";
			this.searchQyname = "";
			this.currentStatus = [];
			this.tableData = [];
			this.total = 0;
			this.getDate(1);
		},

		setStatus() {
			this.currentStatus = [...this.currentStatus];
		},
		setQiyes() {
			console.log(this.searchQyname);
			//this.searchQyname = '';
		},

		//  查看
		requestSee(row) {
			let newPage = this.$router.resolve({
				path: "/selectionwz/" + row.id,
			});
			window.open(newPage.href, "_blank");
		},

		//  分页
		requestPages(value) {
			this.$router.replace({
				path: "/selectionlist",
				query: { page: value },
			});
			this.page = value;
			this.getDate(value);
			return;

			let self = this;
			this.$axios
				.post("/talent/condition", {
					current: value,
					size: self.pageSize,
					statuses: [11, 20, 21],
				})
				.then(function(response) {
					self.tableData = response.data.data.records;
					self.total = response.data.data.total;
				})
				.catch(function(error) {
					console.error(error);
				});
		},

		//锁定
		SetStatus(num, Id) {
			let self = this;
			let daa = {
				id: Id,
				status: num,
			};
			if (num === 0) {
				this.$confirm("锁定后用户将无法登录系统，确定要锁定该用户吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$axios.put("/account", daa).then(function(res) {
							if (res.data.status === 200) {
								self.$message({
									showClose: true,
									message: "锁定成功",
									type: "success",
								});
								self.requestPages(self.page);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消锁定",
						});
					});
			} else {
				this.$confirm("解锁后用户可正常登录和使用系统，确认解锁吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$axios.put("/account", daa).then(function(res) {
							if (res.data.status === 200) {
								self.$message({
									showClose: true,
									message: "解锁成功",
									type: "success",
								});
								self.requestPages(self.page);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消解锁",
						});
					});
			}
		},

		//打开重置手机号
		requestResetPhone(row) {
			this.phoneDialog = true;
			this.phoneForm.id = row.id;
		},

		//重置手机号
		requestPhone() {
			let self = this;
			if (self.$refs.phoneForm.validate()) {
				self.$axios
					.patch("/account/cellphone/reset", {
						id: self.phoneForm.id,
						newCellphone: self.phoneForm.newCellphone,
					})
					.then((response) => {
						if (response.data.status === 200) {
							self.$root.success("重置成功!");
							self.phoneDialog = false;
							self.$refs.phoneForm.reset();
						} else {
							self.$root.warn("重置失败!");
						}
					})
					.catch((error) => {
						console.error(error);
					});
			} else {
				return false;
			}
		},

		reset() {
			this.phoneDialog = false;
			this.$refs.phoneForm.reset();
		},

		//重置密码
		resetPass(row) {
			let self = this;
			this.$confirm("您重置密码吗？ 默认密码：Aa#123")
				.then((_) => {
					this.$axios
						.put("/account/password/reset", {
							newPassword: "Aa#123",
							username: row.account.username,
						})
						.then((response) => {
							if (response.status === 200) {
								self.$message({
									showClose: true,
									message: "重置密码成功",
									type: "success",
								});
							}
						});
				})
				.catch((_) => {
					self.$message({
						showClose: true,
						message: "已取消",
						type: "success",
					});
				});
		},

		//角色编辑
		handleEdit(row) {
			let self = this;
			this.usernum = true;
			this.form.id = row.account.id;
			this.form.status = row.account.status;
			this.rolevalue = [];
			this.title = row.account.username + "修改";
			this.$axios.get("/account?id=" + row.account.id).then(function(res) {
				for (let i = 0; i < res.data.data.roles.length; i++) {
					self.rolevalue.push(res.data.data.roles[i].id);
				}
			});
		},

		//确定修改角色
		submitForm() {
			let self = this;
			this.form.roleIds = this.rolevalue;
			this.$axios.put("/account", this.form).then(function(res) {
				if (res.data.status === 200) {
					self.$message({
						showClose: true,
						message: "修改成功",
						type: "success",
					});
					self.usernum = false;
					self.getDate(1);
				}
			});
		},

		//  导出excel
		requestExport() {
			let self = this;
			if (self.checkedCities.length === 0) {
				self.$root.warn("请选择您要导出的信息");
				return;
			}

			let data = "";
			self.checkedCities.forEach((item) => {
				data += item + ",";
			});
			//searchCondition
			data = data.substr(0, data.length - 1);
			window.location.href = `${self.$configDate.url}/talent/excel/full?excelHeader=` + data + `&statuses=${self.currentStatus}`;
			setTimeout(() => {
				self.centerDialogVisible = false;
			}, 3000);
		},

		// 弹窗中的全选
		handleCheckAllChange(value) {
			this.checkedCities = value ? cityOptions : [];
			this.isIndeterminate = true;
		},

		//  弹窗中的选择框
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.cities.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
		},

		//  选择框
		handleSelectionChange(value) {},
	},
};
</script>

<style lang="less" scoped>
.text-color {
	color: #64b5f6;
	margin-top: 2px;
}

.headtit {
	background: #1090e7;
	height: 50px;
	text-align: left;
	line-height: 50px;
	padding: 0 0 0 16px;
	color: #fff;
	font-size: 16px;
}

.checkboxlist {
	padding: 16px 0 0 12px;
	border: 1px #ddd solid;

	.checkboxitem {
		margin: 0 8px;
		height: 36px;
	}
}

.basicCoxs {
	h2 {
		font-size: 16px;
		margin: 0;
		height: 35px;
		text-align: left;
	}

	.cailxx {
		span {
			margin: 10px 0;
		}
	}

	.fujianx {
		button {
			width: 45%;
			float: left;
			background: #fff;
			margin: 5px;
			text-align: left;
			cursor: pointer;
		}
	}

	span {
		background: #f3f3f3;
		display: block;
		height: 35px;
		text-align: left;
		padding: 0 16px;
		line-height: 35px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		float: left;
		margin: 9px;
		width: 90%;
	}
}

#pagination {
	margin: 32px 0 16px 0;
}

.lid {
	padding: 0 5px;

	button {
		border: 0;
	}
}

.dropdown {
	margin-left: 16px;
}
</style>
