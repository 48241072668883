import { render, staticRenderFns } from "./selectionlist.vue?vue&type=template&id=23ea40cd&scoped=true&"
import script from "./selectionlist.vue?vue&type=script&lang=js&"
export * from "./selectionlist.vue?vue&type=script&lang=js&"
import style0 from "./selectionlist.vue?vue&type=style&index=0&id=23ea40cd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ea40cd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VForm,VRow,VTextField})
